import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin, Form, Input, Select, Tag, message } from 'antd';
import DuckIO from '../ducks/DuckIO';
import AppConfig from '../config/AppConfig';
import { AreaModal_Edit } from '../components';

const recursiveClsIds = (response, array) => {
  for(var i=0; i<response.length; ++i) {
      var subs = response[i].subs;
      if(!!subs) {
          recursiveClsIds(subs, array);
      } else {
          array.push(response[i].classId)
      }
  }
}

export const UpdateRepoModal = ({editRepoId, setEditId, setMyRepoDataLoading, myRepodataLoading}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true); // modal 裡教材資訊撈取 loading
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);  // modal 儲存按鈕loading完 再將modal關閉
    const [data, setData] = useState({});
    const [showEdit, setShowEdit] = useState(false); // modal 視窗
    const [spList, setSpList] = useState([]);
    const [spLoading, setSpLoading] = useState(true);
    const [gradeList, setGradeList] = useState([]); // 教材年級
    const [gradeLoading, setGradeLoading] = useState(true);
    const [classTypeList, setClassTypeList] = useState(''); // 特教班別
    const [classLoading, setClassLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [areaTEvalue, setAreaTEvalue] = useState([]); 
    const [areaCLvalue, setAreaCLvalue] = useState([]); 

    useEffect(() => {
        if (!editRepoId) {
            alert('請洽管理員');

        } else {
            setShowEdit(true);
            DuckIO.doGet(AppConfig.REPO_DETAIL(editRepoId), null, (result) => {
              if (result.resultCode !== 10) {
                  alert('REPO_DETAIL resultCode' + result.resultCode);
              } else {
                setData(result.response);
                let TE1 = result.response.class.filter(x=> x.levelType === 'TWELVE_EDU');
                let TEvalue = TE1.map((x)=>{ return {label: x.className, value: x.classId}});
                let CL1 = result.response.class.filter(x=> x.levelType === 'COLLEGE');
                let CLvalue = CL1.map((x)=>{ return {label: x.className, value: x.classId}});

                setAreaTEvalue(TEvalue);
                setAreaCLvalue(CLvalue);
                setLoading(false);
              }
          });

          DuckIO.doGet(AppConfig.SP_LIST('', 1), null, (result) => {
            if (result.resultCode !== 10) {
                alert('SP_LIST resultCode' + result.resultCode);
            } else {
                let splist = result.response.map((x)=>{
                  return {label: x.name, value: x.id}
                });

                setSpList(!!result.response ? splist : []);
                setSpLoading(false)
            }
          });

          DuckIO.doGet(AppConfig.GRADE_LIST('', 1), null, (result) => {
            if (result.resultCode !== 10) {
              
                alert('GRADE_LIST resultCode' + result.resultCode);
            } else {
                let glist = result.response.map((x)=>{
                    return {label: x.name, value: x.id}
                });
                
                setGradeList(!!result.response ? glist : []);
                setGradeLoading(false);
            }
          });

          // 特教班別
          DuckIO.doGet(AppConfig.CLASS_LIST('', 1), null, (result) => {
            if (result.resultCode !== 10) {
                alert('CLASS_LIST resultCode' + result.resultCode);
            } else {
                let clist = result.response.map((x)=>{
                    return {label: x.name, value: x.key}
                });
                setClassTypeList(!!result.response ? clist : []);
                setClassLoading(false);
            }
        });

        }
    }, []);

    const handleCancel = () => {
        setShowEdit(false);
        setEditId(null);
    }

    const handleOk = () => {
        setSubmitBtnLoading(true);
        form.submit();  //會呼叫form 函式 onFinish實作表單
    };

    const onFinish = (values) => {
      console.log(values)
        let spdata=[];

        values.spobs.forEach(y=>{
          if (isNaN(y)) { 
            //沒更新障礙類別
            spdata.push(y.value);
          } else { 
            //有更新障礙類別
            spdata.push(y)
          }
        });

        let v = { 
          ...values,
          grade: !!values.grade && !!values.grade.value ? values.grade.value : values.grade,
          classType: !!values.classType && !!values.classType.value ? values.classType.value : values.classType,
          spobs: spdata,
          classId: values.classId.map(x=>x.value)
        };
        
        DuckIO.doPost(AppConfig.REPO_UPDATE(editRepoId), v, null, (result) => {
            if (result.resultCode !== 10) {
              message.error(result.exMessage);
              setSubmitBtnLoading(false);
            }
            else {
              setSubmitBtnLoading(false);
              setMyRepoDataLoading(!myRepodataLoading); //回上層重撈進一次撈list
              setEditId(null);
              setShowEdit(false);
            }
        });
      };
    
    const onFinishFailed = (errorInfo) => {
        setSubmitBtnLoading(false);
    };

      return (
        <>
          <Modal
            open={showEdit}
            title={`編輯教材資訊`}
            maskClosable={false}
            closable={!submitBtnLoading}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                取消
              </Button>,
              <Button key="submit" type="primary" loading={submitBtnLoading} onClick={handleOk} disabled={loading}>
                {submitBtnLoading ? '儲存中' : '儲存'}
              </Button>
            ]}
          >
            { (loading || spLoading || gradeLoading || classLoading || data==={})  && <Spin spinning={loading} />}
            {(!loading && !spLoading)  &&
            <Form
                form={form}
                disabled={submitBtnLoading}
                onFinish={onFinish}  // 驗證通過呼叫
                onFinishFailed={onFinishFailed} // 驗證不通過呼叫
                initialValues={{
                  title: data.title,
                  keyword: data.keyword,
                  intro: data.intro,
                  spobs: data.spobs.map((x)=>{
                    return {label: x.spOpsName, value: x.spObs}
                  }),
                  classId: data.class.map(x=>{return {label: x.className, value: x.classId}}),
                  classType: data.classType,
                  grade : data.grade
                }}
                //autoComplete="off"
            >
                <Form.Item key="title" label="教材名稱" name="title" rules={[{ required: true, message: '請輸入教材名稱' }]}>
                      <Input />
                </Form.Item>
                <Form.Item key="intro" label="教材簡介" name="intro" rules={[{ required: false, message: '更新教材簡介' }]}>
                    <Input />
                </Form.Item>
                <Form.Item key="keyword" label="教材關鍵字" name="keyword" rules={[{ required: false, message: '更新教材關鍵字' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="領域" name="classId" rules={[{ required: true, message: '領域至少需選擇一項' }]}>
                    <Button onClick={()=> {setModalOpen(true);}}>更新領域</Button>
                    <div style={{margin:'3px 0px'}}>
                      {areaTEvalue.concat(areaCLvalue).map((x,i)=> {
                                return <Tag color="magenta" key={i}>{x.label}</Tag>
                            })} 
                    </div>
                </Form.Item>
                <Form.Item 
                    label="特教班別"
                    name="classType"
                    rules={[{ required: false, message: '請選擇特教班別' }]}
                >
                    <Select
                        placeholder="請選擇特教班別"
                        allowClear
                        options={classTypeList}
                    />
                </Form.Item>
                <Form.Item 
                    label="障礙類別"
                    name="spobs"
                    rules={[{ required: false, message: '請選擇障礙類別' }]}
                >
                    <Select
                        mode="multiple"
                        placeholder="請選擇障礙類別"
                        allowClear
                        options={spList}
                    />
                </Form.Item>
                <Form.Item 
                    label="教材年級" 
                    name="grade"
                    rules={[{ required: false, message: '請選擇教材年級' }]}
                >
                    <Select
                        placeholder="請選擇教材年級"
                        allowClear
                        options={gradeList}
                    />
                </Form.Item>
            </Form>

            }
          </Modal>
          <AreaModal_Edit 
            modalOpen={modalOpen} 
            modalControl={setModalOpen} 
            setAreaTEvalue={setAreaTEvalue}
            setAreaCLvalue={setAreaCLvalue}
            areaTEvalue={areaTEvalue}
            areaCLvalue={areaCLvalue}
            setFieldValue={form.setFieldValue}
          />
        </>
      )
}
