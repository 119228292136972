import React, { useEffect, useState }from 'react';
import { Loading, AreaModal_SEARCH } from '../components';
import List from 'antd/es/list/index';
import { Input, Card, Typography, Button, Row, Col, Select, Tag, message } from 'antd';
import { LikeFilled , LikeOutlined, SendOutlined, FolderOpenOutlined , CarryOutFilled } from '@ant-design/icons';
import { notification } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DuckIO from '../ducks/DuckIO';
import AppConfig from '../config/AppConfig';
import qs from 'qs';

const { Meta } = Card;
const { Paragraph } = Typography;

const Icon = (props) => {
    return (
        <Button type='text' onClick={props.onClick} disabled={!!props.disabled} style={{ width: '100%' }} loading={props.loading}>
            {props.icon}
            {props.label}
            {props.counts}
        </Button>
    );
};

const CardTitle = (props) => {
    return (
        <Row>
            <Col xs={{span:24}} sm={{span:24}} md={{span:10}} lg={{span:10}} style={{fontSize:'1.3em', overflow: 'hidden',textOverflow:'ellipsis'}} >
                {props.data.title}
                {props.data.invalid ? 
                    <Tag style={{marginLeft: '3px'}}  bordered={false} color='red' key={props.data.classId}>已下架</Tag> : ''
                }
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:4}} lg={{span:4}}>
                <Tag color='blue'>{!props.data.grade ? '' : props.data.grade.label}</Tag>
            </Col>
            <Col xs={{span:24}} sm={{span:24}} md={{span:10}} lg={{span:10}}
                style={{ overflow: 'hidden',textOverflow:'ellipsis'}}>
                {(props.data.class || []).map(y=>{
                        return <Tag color='green' key={y.id}>{y.className}</Tag>
                    })}
            </Col>
        </Row>
    );
};

const CardIntro = (props) => {
    return (
        <>
        <Paragraph ellipsis={{ rows: 1 }}>
            {props.data.teacher || ''}
        </Paragraph>
        <Paragraph ellipsis={{ rows: 2 }} italic={true}>
            {props.data.intro || ''}
        </Paragraph>
        </>
    );
};

const openNotification = ({ name, url }) => {
    notification.open({
        message: `分享教材【${name}】`,
        description: `已複製教材網址 ${url}`,
    });
};

export const AllList = ({ menu }) => {
    const [lists, setLists] = useState(null);
    const [listLoading, setListLoading] = useState(true);
    const [searchData, setSearchData] = useState(null);
    const [selectObj, setSelectObj] = useState([]);
    const [areaTEvalue, setAreaTEvalue] = useState([]);
    const [areaCLvalue, setAreaCLvalue] = useState([]);
    const [gradeValue, setGradeValue] = useState([]);
    const [classValue, setClassValue] = useState([]);
    const [spValue, setSpValue] = useState([]);
    const [inputValue, setInputValue] = useState(null);
    const [gradeList, setGradeList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [spList, setSpList] = useState([]);
    const [optionLoading, setOptionLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [downLoadBtn, setDownLoadBtn] = useState('');
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [cnt, setCnt] = useState(0);
    const [pageMaxSize, setPageSize] = useState(AppConfig.DEFAULT_MAX_PAGE_SIZE);

    // 搜尋下拉選單
    useEffect(() => {
        //年級
        DuckIO.doGet(AppConfig.GRADE_LIST('', 1), null, (result) => {
            if (result.resultCode !== 10) {
                alert('GRADE_LIST resultCode '+result.resultCode);
            } else {
                let glist = result.response.map((x)=>{
                    return {label: x.name, value: x.id}
                });
                setGradeList(!!result.response ? glist : []);
            }
        });
        
        //類別
        DuckIO.doGet(AppConfig.CLASS_LIST('', 1), null, (result) => {
            if (result.resultCode !== 10) {
                alert('CLASS_LIST resultCode '+result.resultCode);
            } else {
                let clist = result.response.map((x)=>{
                    return {label: x.name, value: x.key}
                });
                setClassList(!!result.response ? clist : []);
            }
        });

        // 障礙類別
        DuckIO.doGet(AppConfig.SP_LIST('', 1), null, (result) => {
            if (result.resultCode !== 10) {
                alert('CLASS_LIST resultCode '+result.resultCode);
            } else {
                let splist = result.response.map((x)=>{
                    return {label: x.name, value: x.id}
                });
                setSpList(!!result.response ? splist : []);
            }
        });
        setOptionLoading(false);
    }, []);

    // 首頁選擇menu
    useEffect(() => {
            setListLoading(true);
            setInputValue('');
            setSelectObj([]);
            setAreaTEvalue([]);
            setAreaCLvalue([]);
            setGradeValue([]);
            setClassValue([]);
            setSpValue([]);
            setPageSize(AppConfig.DEFAULT_MAX_PAGE_SIZE);

            switch (menu) {
                case '1':
                    DuckIO.doGet(AppConfig.REPO_ALL('', 1, pageMaxSize), null, (result) => {
                        setLists(!!result.response ? result.response : []);
                        setListLoading(false);
                        
                    });
                    break;
                case '2':
                    DuckIO.doGet(AppConfig.REPO_FAVORITE('', 1, pageMaxSize), null, (result) => {
                        setLists(!!result.response ? result.response : []);
                        setListLoading(false);
                        
                    });
                    break;
                default:
                    DuckIO.doGet(AppConfig.REPO_ALL('', 1, pageMaxSize), null, (result) => {
                        setLists(!!result.response ? result.response : []);
                        setListLoading(false);
                        
                    });
                    break;
        }
    }, [menu]);

    // 搜尋時 (加上 搜尋的參數searchData)
    useEffect(() => {
        setListLoading(true);
        switch (menu) {
            case '1':
                DuckIO.doGet(AppConfig.REPO_ALL(searchData ? searchData : '', 1, pageMaxSize), null, (result) => {
                    setLists(!!result.response ? result.response : []);
                    setListLoading(false);
                });
                break;
            case '2':
                DuckIO.doGet(AppConfig.REPO_FAVORITE(searchData ? searchData : '', 1, pageMaxSize), null, (result) => {
                    setLists(!!result.response ? result.response : []);
                    setListLoading(false);
                });
                break;
            default:
                DuckIO.doGet(AppConfig.REPO_ALL(searchData ? searchData : '', 1, pageMaxSize), null, (result) => {
                    setLists(!!result.response ? result.response : []);
                    setListLoading(false);
                });
                break;
    }
}, [searchData]);

    const search = () =>{
        setSearchData(null);
        // 領域
        let classArr = areaTEvalue.concat(areaCLvalue);

        let searchValue = qs.stringify({
            keyword: inputValue === '' ? null : inputValue,
            class: classArr.length === 0 ? null : classArr.map(x=>x.value),
            grade: gradeValue.length === 0 ? null : gradeValue.map(x=>x.value),
            classType: classValue.length === 0 ? null : classValue.map(x=>x.value),
            spObs: spValue.length === 0 ? null : spValue.map(x=>x.value)
        }
        ,{indices: false, skipNulls: true});

        setSearchData(searchValue);
    }

    return (
        <>
            {(optionLoading) ? <Loading /> :
                <div 
                    style={{padding: '20px', 
                    borderBottom: '1px solid #F1F1F1', 
                    backgroundColor: 'white', 
                    boxShadow: '0 1px 5px lightgrey'}}
                >
                    <Row align='middle' gutter={[8, 8]}> 
                        {/* 教材名稱 */}
                        <Col span={12}>   
                            <Input
                            allowClear
                            value={inputValue}
                            placeholder="搜尋教材名稱/教材簡介/作者/關鍵字"
                            title="搜尋教材名稱/教材簡介/作者/關鍵字"
                            onChange={(e) => setInputValue(e.target.value)} 
                        />
                        </Col>
                        {/* 領域 */}
                        <Col span={12}>
                            <Select 
                                aria-label="請選擇教材領域"
                                showArrow={false}
                                autoClearSearchValue={false}
                                allowClear={true}
                                mode='multiple' 
                                style={{width:'100%'}} 
                                open={false}
                                value={selectObj}
                                onClick={()=>setModalOpen(true)}
                                onChange={(v)=>{
                                    // select bar 按X時啟動
                                    // v是select bar 刪除選項後，剩餘選項
                                    let alterDelArr = [];
                                    let new_TW = [];
                                    let new_CL = [];
                                    
                                    areaTEvalue.concat(areaCLvalue).map((x)=> {
                                        v.forEach(y=>{
                                            if(x.value === y) {
                                                alterDelArr.push(x);
                                            }
                                        })
                                    });
                                    
                                    alterDelArr.forEach(x=>{
                                        if(x.type === "TWELVE_EDU") {
                                            new_TW.push(x);
                                        } else {
                                            new_CL.push(x);
                                        }
                                    })
                                   
                                    setAreaTEvalue(new_TW);
                                    setAreaCLvalue(new_CL);
                                    setSelectObj(alterDelArr);
                                }} 
                                placeholder='請選擇教材領域'
                                maxTagCount='responsive'
                            />
                        </Col>
                        {/* 年級 */}
                        <Col span={12}>   
                            <Select
                                aria-label="請選擇教材年級" 
                                showArrow={true}
                                allowClear={true}
                                filterOption={(i,o)=>{
                                    return o.label.indexOf(i) >=0
                                }}
                                mode='multiple' 
                                style={{width:'100%'}} 
                                options={gradeList} 
                                onChange={(v,o) => {
                                    setGradeValue(o);
                                }} 
                                optionFilterProp="label"
                                placeholder='請選擇教材年級'
                                maxTagCount='responsive'
                                value={gradeValue}
                            />
                        </Col>
                        {/* 特教班別 */}
                        <Col span={12}>   
                            <Select 
                                aria-label="請選擇特教班別" 
                                showArrow={true}
                                allowClear={true}
                                mode='multiple' 
                                style={{width:'100%'}} 
                                filterOption={(i,o)=>{
                                    return o.label.indexOf(i) >=0
                                }}
                                options={classList} 
                                onChange={(v,o) => {
                                    setClassValue(o);
                                }} 
                                placeholder='請選擇特教班別'
                                maxTagCount='responsive'
                                value={classValue}
                            />
                        </Col>
                        {/* 障礙類別 */}
                        <Col span={12}>   
                            <Select
                                aria-label="請選擇障礙類別" 
                                showArrow={true}
                                allowClear={true}
                                mode='multiple' 
                                style={{width:'100%'}} 
                                filterOption={(i,o)=>{
                                    return o.label.indexOf(i) >=0
                                }}
                                options={spList} 
                                onChange={(v,o) => {
                                    console.log(v,o)
                                    setSpValue(o);
                                }} 
                                placeholder='請選擇障礙類別'
                                maxTagCount='responsive'
                                value={spValue}
                            />
                        </Col>
                        <Col span={12}>
                            <Button type="primary" onClick={search}>搜尋</Button>
                        </Col>
                    </Row>
                </div>
                
            }
            
            {(listLoading) ?  <Loading />
            :
            <div style={{ margin: '5px 20px' }}>
                <List
                locale={{emptyText: '無教材'}}
                itemLayout="vertical"
                size="large"
                pagination={{
                    onChange: (page,pageSize) => {
                            setPageSize(pageSize);
                            switch (menu) {
                                case '1':
                                    DuckIO.doGet(AppConfig.REPO_ALL(searchData? searchData : '', page, pageSize), null, (result) => { 
                                        setLists(!!result.response ? result.response : []);
                                    });
                                    break;
                                case '2':
                                    DuckIO.doGet(AppConfig.REPO_FAVORITE(searchData? searchData : '', page, pageSize), null, (result) => {
                                        setLists(!!result.response ? result.response : []);
                                    });
                                    break;
                                case '3':
                                    DuckIO.doGet(AppConfig.REPO_MY_EXPERTISE(searchData? searchData : '', page, pageSize), null, (result) => {
                                        setLists(!!result.response ? result.response : []);
                                    });
                                    break;
                                case '4':
                                    DuckIO.doGet(AppConfig.REPO_MY_REPO(searchData? searchData : '', page, pageSize), null, (result) => {
                                        setLists(!!result.response ? result.response : []);
                                    });
                                    break;
                                default:
                                    DuckIO.doGet(AppConfig.REPO_ALL(searchData? searchData : '', page,pageSize), null, (result) => {
                                        console.log('de',pageSize)
                                        setLists(!!result.response ? result.response : []);
                                    });
                                    break;
                            }
                    },
                    showSizeChanger: true,
                    current: lists.page,
                    pageSize: pageMaxSize,
                    total: lists.totalRecords,
                    itemRender: (current, type, originalElement) => {
                        if (type === 'prev') {
                            return <a>＜</a>;
                        }
                        if (type === 'next') {
                            return <a>＞</a>;
                        }
                        return originalElement;
                    },
                }}
                dataSource={lists.items}
                renderItem={(x) => (
                    <Card
                        key={x.id}
                        style={{ width: '100%', marginTop: 20, cursor: 'pointer', 
                        boxShadow:'0 0 0 -2px rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.08),0 4px 15px 8px rgba(0, 0, 0, 0.05)' }}
                        onClick={(event) => {
                            event.stopPropagation();
                            window.open(`/repo/${x.id}`);
                        }}
                        actions={[
                            <CopyToClipboard text={DuckIO.path(AppConfig.REPO_SHARED_LINK(x.id))}>
                                <Icon
                                    icon={<SendOutlined key="share" />}
                                    label={'分享'}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        openNotification({ name: x.title, url: `${window.location.href}/repo/${x.id}` });
                                        DuckIO.doGet(AppConfig.REPO_SHARED_INC(x.id))
                                    }}
                                />
                            </CopyToClipboard>,
                            <Icon
                                disabled={!AppConfig.MEMBER}
                                icon={x.is_like ? <LikeFilled style={{ color:'#2CA6D4' }}/> : <LikeOutlined key="like" />}
                                label={x.is_like ? '已按讚' : '按讚'}
                                counts={x.like_count}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    x.is_like = !x.is_like
                                    if(x.is_like) x.like_count++
                                    else x.like_count--
                                    setCnt(cnt + 1);
                                     DuckIO.doPost(AppConfig.REPO_LIKE(x.id, x.is_like));
                                    // 待處理> 偵測是否按過按鈕 按讚>>>收回讚
                                }}
                            />,
                            <Icon
                                disabled={!AppConfig.MEMBER}
                                icon={x.is_archive ? <CarryOutFilled style={{ color:'#F6A700' }} /> : <FolderOpenOutlined key="archive" />}
                                label={x.is_archive ? '已收藏' : '收藏'}
                                counts={x.archiveCount}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    x.is_archive = !x.is_archive;
                                    if(x.is_archive) x.archiveCount++
                                    else x.archiveCount--
                                    setCnt(cnt + 1);
                                    DuckIO.doPost(AppConfig.REPO_ARCHIVE(x.id, x.is_archive));
                                }}
                            />,
                            <Icon
                                disabled={!AppConfig.MEMBER || downloadLoading}
                                label={downLoadBtn === x.id ? '下載中' :'下載'}
                                counts={x.download_count}
                                loading={downLoadBtn === x.id}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setDownloadLoading(true);
                                    setDownLoadBtn(x.id);
                                    DuckIO.download(DuckIO.path(AppConfig.REPO_DOWNLOAD_ARCHIVE(x.id)), `${x.id}.zip`,()=>{
                                        x.download_count ++;
                                        let count = x.download_count + 1;
                                        setDownLoadBtn('');
                                        setDownloadLoading(false);
                                        message.success('下載成功');
                                    });
                                    
                                }}
                            />,
                        ]}>
                        <div title='查看教材將開啟新視窗'>
                            <Meta 
                                title={<CardTitle data={x} />} 
                                description={<CardIntro data={x} />}  
                            />
                        </div>
                    </Card>
                   
                )}
                
                />
                <AreaModal_SEARCH 
                    modalOpen={modalOpen} 
                    modalControl={setModalOpen} 
                    setAreaTEvalue={setAreaTEvalue}
                    setAreaCLvalue={setAreaCLvalue}
                    areaTEvalue={areaTEvalue}
                    areaCLvalue={areaCLvalue}
                    setSelectObj={setSelectObj}
                    
                />
            </div>
            }
        </>
        
    );
};
