import React, { useEffect, useState } from 'react';
import { Form, Select, Button, Input, Row, Col, Tag, Card, Avatar, Divider, Switch, message } from 'antd';
import DuckIO from '../ducks/DuckIO';
import AppConfig from '../config/AppConfig';
import { Loading } from '../components/index';
import { AreaModal_Edit } from '../components';

export const MyConfig = () => {
    const mbsn = AppConfig.MEMBER.mbSn;
    const [form] = Form.useForm();
    const [config, setConfig] = useState([]);
    const [configLoading, setConfigLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [SubmitLoading, setSubmitLoading] = useState(false);
    const [classType, setClassType] = useState([]); // 特教類別
    const [modalOpen, setModalOpen] = useState(false); 
    const [formDone, setFormDone] = useState(false); 
    const [areaTEvalue, setAreaTEvalue] = useState([]); 
    const [areaCLvalue, setAreaCLvalue] = useState([]); 

    useEffect(() => {
        setLoading(true);
        DuckIO.doGet(AppConfig.MY_CONFIG(mbsn), null, (result) => {
            if (result.resultCode !== 10) {
                alert('resultCode_MY_CONFIG'  + result.resultCode);
            } else {
                let areaInitial = result.response.expertiseList.map(x=>{
                    return {label: x.className, value: x.classId}
                });

                setConfig({classId: areaInitial, ...result.response});
                let TE1 = result.response.expertiseList.filter(x=> x.levelType === 'TWELVE_EDU');
                let TEvalue = TE1.map((x)=>{ return {label: x.className, value: x.classId}});
                let CL1 = result.response.expertiseList.filter(x=> x.levelType === 'COLLEGE');
                let CLvalue = CL1.map((x)=>{ return {label: x.className, value: x.classId}});

                setAreaTEvalue(TEvalue);
                setAreaCLvalue(CLvalue);
                setConfigLoading(false);
            }
        }); 
        
        // 特教類別
        DuckIO.doGet(AppConfig.CLASS_LIST('', 1), null, (result) => {
            if (result.resultCode !== 10) {
                alert('resultCode_CLASS_LIST '+result.resultCode);
            } else {
                let clist = result.response.map((x)=>{
                        return {label: x.name, value: x.key}
                    });
                setClassType(!!result.response ? clist : []);
            }
        });
        setLoading(false);
    }, [formDone]);

    const onFinish = (values) => {
        setSubmitLoading(true);
        let data = {
            nickname : values.nickname,
            classType : values.classType,
            mailNotify : values.mailNotify,
            classId : values.classId.map(x=>x.value)
        }

        DuckIO.doPut(AppConfig.MY_UPDATE(mbsn), data, (result) => {
            if(result.resultCode === 10) {
                message.success('更新成功');
                setSubmitLoading(false);
                setFormDone(!formDone);
            } else {
                message.error('更新失敗，請洽詢管理員');
            }
            
        });
      };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    if (configLoading || loading) {
        return  <Loading />;
    }

   
    return (
        <Row style={{margin:20}}>
            <Col xs={{span: 2}} sm={{span: 6}} md={{span: 6}}></Col>
            <Col xs={{span: 20}} sm={{span: 12}} md={{span: 12}}>
            <Form
                form={form}
                disabled={SubmitLoading}
                initialValues={config}
                name="basic"
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                onFinish={onFinish}  // 驗證通過呼叫
                onFinishFailed={onFinishFailed} // 驗證不通過呼叫
            >
                <Card>
                    <Row align={'middle'} justify={'center'}>
                        <Col>
                        <Avatar alt="個人大頭貼" size={{ xs: 60, sm: 60, md: 80, xl: 100}} src={config.photo}/>
                        </Col>
                    </Row>
                    <Row align={'middle'} justify={'center'}>
                        <Col style={{fontWeight:'bold', fontSize:'16px'}}>{config.email}</Col>
                    </Row>
                    <Divider />
                    <Form.Item label='暱稱' name="nickname" rules={[{ required: true, message: '請輸入暱稱' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item 
                        label="任教班別"
                        name="classType"
                        rules={[{ required: true, message: '請輸入任教班別' }]}
                    >
                        <Select
                            placeholder="請選擇特教類別"
                            allowClear
                            options={classType}
                        />
                    </Form.Item>
                    <Form.Item 
                        label="專長領域" 
                        name="classId"
                    >
                    <Button onClick={()=> {setModalOpen(true);}}>
                        {config.classId.length === 0 ? '選擇領域' :'更新領域'}
                    </Button>
                    <div>
                        {areaTEvalue.concat(areaCLvalue).map((x,i)=> {
                            return <Tag color="magenta" key={i}>{x.label}</Tag>
                        })}   
                     </div> 
                    </Form.Item>
                    <Form.Item label='接受Mail通知' valuePropName="checked" name="mailNotify">
                        <Switch aria-label="點擊切換通知開關" />
                    </Form.Item>
                    <Row>
                        <Col style={{fontWeight:'bold'}} span={24}>
                            <Button block type="primary" htmlType="submit" loading={SubmitLoading}>
                                { !SubmitLoading ? '儲存' : '儲存中'}
                            </Button>
                        </Col>
                    </Row>
                </Card> 
            </Form>
            
            <Col xs={{span: 2}} sm={{span: 6}} md={{span: 6}}></Col>
            <AreaModal_Edit 
                modalOpen={modalOpen} 
                modalControl={setModalOpen} 
                setAreaTEvalue={setAreaTEvalue}
                setAreaCLvalue={setAreaCLvalue}
                areaTEvalue={areaTEvalue}
                areaCLvalue={areaCLvalue}
                setFieldValue={form.setFieldValue}
            />
            </Col>
        </Row>
    );
}
